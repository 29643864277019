<template>
  <div class="brand">
    <div class="top-banner">
      <div class="container title">
        <h1>品牌介绍</h1>
        <h2>BRAND INTRODUCTION</h2>
      </div>
    </div>
    <div class="content container">
      <div class="common-middle-title">
        <h1>四大品牌</h1>
        <h3>···</h3>
      </div>
      <div class="hidden-xs">
         <div class="brand-block" @click="toDermalogica">
          <img src="../assets/brand/brand_1.png" alt="">
        </div>
        <div class="brand-block1">
          <img src="../assets/brand/brand_2.png" alt="" @click="toKs">
          <img src="../assets/brand/brand_3.png" alt="" @click="toSepai">
          <img src="../assets/brand/brand_4.png" alt="" @click="toJde">
        </div>
      </div>

      <div class="visible-xs">
        <swiper :options="swiperOption">
          <swiper-slide class="swiper-slide" v-for="(item,index) in brandList" :key="index">
              <img :src="item.image_Mobile" @click="toBrandDetail(item.path)"/>
              <div class="item-info-btn" v-if="item.path"  @click="toBrandDetail(item.path)">
                了解详情 <img src="../assets/home/brand/right_black.png" alt="">
              </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="common-middle-title">
        <h1>其他品牌</h1>
        <h3>···</h3>
      </div>
      <div class="brand-block2">
        <img src="../assets/brand/brand_other.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Brand',
  data(){
    return {
      swiperOption: {
        slidesPerView: 1.2,
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
      },
      brandList: [
        {
          name: 'dermalogica',
          desc: '美国专业院线第一品牌',
          image_Mobile: require('../assets/home/brand/brand_mobile_1.png'),
          path: '/brand/dermalogica'
        },
        {
          name: 'Kate Somervile',
          desc: '好莱坞明星沙龙高端个护品牌',
          image_Mobile: require('../assets/home/brand/brand_mobile_2.png'),
          path: '/brand/kateSomerville'
        },
        {
          name: 'Murad',
          desc: '欧洲高奢贵妇品牌',
          image_Mobile: require('../assets/home/brand/brand_mobile_3.png'),
          path: '/brand/murad'
        },
        {
          name: "JEAN D'ESTREES",
          desc: '法国十大专业院线品牌',
          image_Mobile: require('../assets/home/brand/brand_mobile_4.png'),
          path: '/brand/jde'
        }
      ],
    }
  },
  methods: {
    toJde(){
      this.$router.push({path:'/brand/jde'})
    },
    toDermalogica(){
      this.$router.push({path: '/brand/dermalogica'})
    },
    toKs(){
      this.$router.push({path: '/brand/kateSomerville'})
    },
    toSepai(){
      this.$router.push({path: '/brand/murad'})
    },
    toBrandDetail(path){
      this.$router.push({path: path})
    }
  }
}
</script>

<style lang="scss" scoped>
.brand {
  .content {
    padding: 100px 0;
    .common-middle-title {
      margin-bottom: 40px;
    }
    .brand-block1 {
      padding: 40px 0 100px 0;
      display: flex;
      overflow: hidden;
      img {
        flex: 1;
        width: auto;
        height: auto;
        margin-right: 20px;
      }
      img:last-child {
        margin-right: 0;
      }
    }
    .brand-block2 img {
      width: auto;
      height: auto;
      max-width: 100%;
    }
    .brand-block img, .brand-block1 img { 
      -moz-transition: all ease-in-out .3s;
      -webkit-transition: all ease-in-out .3s;
      transition: all ease-in-out .3s;
    }
    .brand-block img:hover, .brand-block1 img:hover {
      box-shadow:0px 2px 40px 0px rgba(0,0,0,0.2);
      transform: scale(1.02);
    }
    .swiper-slide {
      position: relative;
      margin-bottom: 30px;
      .item-info-btn {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        width: 100px;
        height: 24px;
        background:rgba(255,255,255,0.3);
        border:1px solid rgba(51,51,51,.3);
        line-height: 34px;
        cursor: pointer;
        transition: all .2s;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        border-radius: 3px;
        img {
          width: 15px;
          height: 15px;
          margin-left: 5px;
        }
      }
    }
  }
}
@media (max-width: 768px){
  .brand .content {
    padding: 15px;
    .common-title {
      margin-bottom: 15px;
    }
    .brand-block1 {
      padding: 5px 0 15px 0;
      img {
        margin-right: 5px;
        height: auto;
      }
    }
  }
}
</style>
<style lang="scss">
.brand {
  .swiper-slide-active {
    box-shadow:0px 5px 15px 0px rgba(0,0,0,0.2);
    left: -20px;
  }
  .swiper-slide {
    transition: all .1s;
    margin-bottom: 20px;
    img {
      display: block;
    }
  }
  .swiper-slide-prev {
    transform: scale(.9);
    left: -30px;
  }
  .swiper-slide-next {
    transform: scale(.9);
    left: -20px;
  }
}
</style>